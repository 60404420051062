import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import NavBar from "./commonComponent/Drawer/NavBar";
// import Home from "./screens/Home";
// import Login from "./screens/Login";
// import Scanning from "./screens/Scanning";
// import RoutesDetailisfdsa from "./screens/SupervisorRouteListing";
// import RouteListing from "./screens/RouteListing";
// import Supervisor from "./screens/Supervisor";
// import TableDashboard from "./screens/TableDashboard";
import Loader from "./commonComponent/Loader/Loader";

const Home = lazy(() => import("./screens/Home"));
const Login = lazy(() => import("./screens/Login"));
const Scanning = lazy(() => import("./screens/Scanning"));
const FnvScanning = lazy(() => import("./screens/FnvScanning"));
const SupervisorRouteListing = lazy(() => import("./screens/SupervisorRouteListing"));
const RouteListing = lazy(() => import("./screens/RouteListing"));
const Supervisor = lazy(() => import("./screens/Supervisor"));
const TableDashboard = lazy(() => import("./screens/TableDashboard"));
const ColdScanning = lazy(()=> import("./screens/ColdScanning"))
const PocColdScanning = lazy(()=> import("./screens/ColdScanningPoc"))
const AgentOrderListing = lazy(()=> import("./screens/ColdAgentConsumerListing"))
const ReusableBag = lazy(()=> import("./screens/ReusableBag"))
const ReturnReusableBag = lazy(()=> import("./screens/ReturnReusableBag"))
const TableStatus = lazy(()=> import("./screens/TableStatus"))

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/login" element={<Login />}></Route> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        ></Route>
        
        <Route element={<ProtectedRoute />}>
          <Route element={<WithNavbar />}>
            {/* <Route path="/" element={<Home />}></Route> */}
            {/* <Route path="/scanning" element={<Scanning />}></Route> */}
            {/* <Route path="/routedetail/*" element={<RoutesDetail />}></Route> */}
            {/* <Route path="/route/*" element={<RouteListing />}></Route> */}
            {/* <Route path="/supervisor/*" element={<Supervisor />}></Route> */}
            {/* <Route path="/table-dashboard/*" element={<TableDashboard />}></Route> */}
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            ></Route>
            {/* <Route
              path="/scanning"
              element={
                <Suspense fallback={<Loader />}>
                  <Scanning />
                </Suspense>
              }
            ></Route> */}
            <Route
              path="/fnv-scanning/"
              element={
                <Suspense fallback={<Loader />}>
                  <FnvScanning />
                </Suspense>
              }
            ></Route>
            <Route
              path="/routedetail/*"
              element={
                <Suspense fallback={<Loader />}>
                  <SupervisorRouteListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/route/*"
              element={
                <Suspense fallback={<Loader />}>
                  <RouteListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/supervisor/*"
              element={
                <Suspense fallback={<Loader />}>
                  <Supervisor />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-dashboard/*"
              element={
                <Suspense fallback={<Loader />}>
                  <TableDashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-status/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <TableStatus />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-dashboard/*"
              element={
                <Suspense fallback={<Loader />}>
                  <TableDashboard />
                </Suspense>
              }
            ></Route>
            {/* <Route
              path="/cold-scanning"
              element={
                <Suspense fallback={<Loader />}>
                  <ColdScanning/>
                </Suspense>
              }
            ></Route>    */}
            <Route
              path="/cold-scanning/"
              element={
                <Suspense fallback={<Loader />}>
                  <PocColdScanning/>
                </Suspense>
              }
            ></Route>   
            <Route
              path="/retailers-order-list/:agentId/:pickerId/"
              element={
                <Suspense fallback={<Loader />}>
                  <AgentOrderListing />
                </Suspense>
              }
            ></Route>     
            {/* <Route
              path="/return-bag/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <ReusableBag />
                </Suspense>
              }
            ></Route>   */}
            <Route
              path="/reusable-return-bag/"
              element={
                <Suspense fallback={<Loader />}>
                  <ReturnReusableBag />
                </Suspense>
              }
            ></Route>  
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
